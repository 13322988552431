var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-text-field", {
        attrs: {
          "prepend-inner-icon": "mdi-magnify",
          clearable: "",
          "hide-details": "",
          "clear-icon": "mdi-close-circle",
          solo: "",
        },
        model: {
          value: _setup.searchText,
          callback: function ($$v) {
            _setup.searchText = $$v
          },
          expression: "searchText",
        },
      }),
      _setup.searchItems.length == 0
        ? _c(
            "v-row",
            { staticClass: "align-center" },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(" Geen resultaten gevonden! "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _setup.searchItems.length > 0
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-list",
                    { attrs: { "two-line": "" } },
                    [
                      _vm._l(_setup.searchItems, function (item, index) {
                        return [
                          _c(
                            "v-list-item",
                            {
                              key: index,
                              staticClass: "relationSelectItem",
                              attrs: {
                                disabled: _setup.isAlreadySelected(item.id),
                              },
                              on: {
                                click: function ($event) {
                                  return _setup.addSelected(item)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(item.description)),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { key: `search-div-${index}` }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _setup.selectedItems.length > 0
        ? _c(
            "v-row",
            { staticClass: "align-center mt-5" },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(" " + _vm._s(_setup.selectedText) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _setup.selectedItems.length > 0
                ? _c(
                    "v-list",
                    { attrs: { "two-line": "" } },
                    [
                      _vm._l(_setup.selectedItems, function (item, index) {
                        return [
                          _c(
                            "v-list-item",
                            { key: index, staticClass: "relationSelectItem" },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(item.description)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                { key: item.id },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _setup.removeSelected(item.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-close-circle"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { key: `sel-div-${index}` }),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }